!function(window) {
// --- ARCTUR form custom validators --- //
// --- by. Aleš Brelih

function formValidators($) {
    $(document).ready(function(){

        // --- ONLOAD --- //

        //set bootstrap validator
        $("form.arctur-form").validator({
            delay: 1500 //waits 2s before displaying errors
        });

        //if posted gets rejected exception
        //this only applies to a group of checkboxes && not a single required checkbox
        if($("form.arctur-form .form-checkboxes.one-required input[type='checkbox']").length > 1){
            //get num of checked
            var numOfChecked = $(".form-checkboxes.one-required input[type='checkbox']:checked").length;

            //if atleast 1 checked
            if(numOfChecked > 0){
                //find unchecked and remove requried
                $("form.arctur-form .form-checkboxes.one-required input[type='checkbox']:not(:checked)").removeAttr("required");
            }
            if(numOfChecked == 0){ //reset required attr to all if none is checked
                $("form.arctur-form .form-checkboxes.one-required input[type='checkbox']").attr("required",true);
            }
        }


        // --- EVENTS --- //

        //group of checkbox validator
        $("form.arctur-form .form-checkboxes.one-required").on("change","input[type='checkbox']",function(ev){

            //this only applies to a group of checkboxes && not a single required checkbox
            if($("form.arctur-form .form-checkboxes.one-required input[type='checkbox']").length > 1){
                //get num of checked
                var numOfChecked = $(".form-checkboxes.one-required input[type='checkbox']:checked").length;

                //if atleast 1 checked
                if(numOfChecked > 0){
                    //find unchecked and remove requried
                    $("form.arctur-form .form-checkboxes.one-required input[type='checkbox']:not(:checked)").removeAttr("required");
                }
                if(numOfChecked == 0){ //reset required attr to all if none is checked
                    $("form.arctur-form .form-checkboxes.one-required input[type='checkbox']").attr("required",true);
                }
            }
            

        });

        ////////////////////////////////////////////////////

        // ------------------------------------------------------ //
        // ----- CLOSING BOOTSTRAP VALIDATION ERRORS - FORM ----- //

        $("form.arctur-form").on("click","ul.form-error-list .close-btn",function(){

            //help block to be clecared
            var currentErrBlock = $(this).closest(".help-block.with-errors");
            
            //in which close btn is located
            var currentFromGroup = $(this).closest(".form-group.has-error");

            //clear form group of errors
            $(currentErrBlock).fadeOut(function(){
                $(currentErrBlock).empty();
                $(currentFromGroup).removeClass("has-error");
                $(currentErrBlock).fadeIn();
            });
            
            
        });

        ////////////////////////////////////////////////////


        // ------------------------------------------------------------- //
        // ----- CLOSING BOOTSTRAP VALIDATION ERRORS - SERVER SIDE ----- //

        // PREREQ: INSIDE MAIN/ARTICLE

        $("main article, aside").on("click",".form-errors-wrapper .btn-close-server-err",function(){

            //error block to be cleared
            var currentErrBlock = $(this).closest(".server-error-wrapper");
            

            //clear form group of errors
            $(currentErrBlock).fadeOut(function(){
                
                var errorsInDOM = $(this).parent(".form-errors-wrapper");
                $(currentErrBlock).remove();
                errorsInDOM = $(errorsInDOM).find(".server-error-wrapper");
                console.log(errorsInDOM);

                if(errorsInDOM == 0){
                    //remove wrapper from DOM if 0 errors
                    $(".form-errors-wrapper").remove();
                }
            });   
        });

        ////////////////////////////////////////////////////
        
    });
}

module.exports = formValidators;
window.formValidators = formValidators;
}.call(window, window);