!function(window) {
function extend($) {

	jQuery = $;
	/*
     --- Equal element heights --- 
     */

	// make all given objects the same height
	$.fn.equalHeightColumns = function () {
	    var tallest = 0;

	    $(this).each(function () {
	        if ($(this).outerHeight(true) > tallest) {
	            tallest = $(this).outerHeight(true);
	        }
	    });

	    $(this).each(function () {
	        var diff = 0;
	        diff = tallest - $(this).outerHeight(true);
	        $(this).height($(this).height() + diff);
	    });
	};

	// make all given objects the same height across multiple rows (automated columns number detection)
	$.fn.equalHeightMultirowColumns = function () {
		var colWidth = $(this).eq(0).outerWidth(true);
	    var parentWidth = $(this).eq(0).parent().outerWidth();
	    var nCols = parseInt(parentWidth / colWidth);
	    $(this).equalHeightNRowColumns(nCols);
	}

	// make all given objects in the same row of equal height, across N columns
	$.fn.equalHeightNRowColumns = function (nCols) {
	    var elmnts = $(this);
	    var nElements = elmnts.length;

	    var aHeights = new Array();

	    var i = 0;
	    var cnt = 0;
	    var tallest = 0;
	    elmnts.each(function () {
	        var el = $(this);

	        if ($(this).outerHeight(true) > tallest) {
	            tallest = $(this).outerHeight(true);
	        }

	        if (cnt == nCols - 1) {
	            aHeights.push(tallest);
	            tallest = 0;
	            cnt = 0;
	        } else {
	            if (i == nElements - 1) {
	                aHeights.push(tallest);
	                tallest = 0;
	            }
	            cnt++;
	        }

	        i++;
	    });

	    cnt = 0;
	    var arrCnt = 0;
	    elmnts.each(function () {
	        var el = $(this);

	        var diff = aHeights[arrCnt] - el.outerHeight(true);
	        el.height(el.height() + diff);

	        if (cnt < nCols - 1) {
	            cnt++;
	        } else {
	            cnt = 0;
	            arrCnt++;
	        }
	    });
	};

	// remove any previously set equal heights

	$.fn.removeEqualHeights = function () {
	    $(this).each(function () {
	        $(this).css('height', '');
	    });
	};

    // --------------------------------------- //


    /* 
    Marks external links 
    */
	$.fn.markExternalLinks = function () {
	    $(this).filter(function () {
	        return this.hostname && this.hostname !== location.hostname && ($(this).children('img').size() == 0);
	    }).addClass("external");
	}


    // --------------------------------------- //


    /* 
    --- populate text fields and clear them on focus --- 
    */
	jQuery.fn.textBoxHint = function () {
		return this.each(function () {
	    	var t = jQuery(this); // get jQuery version of 'this'
			var title = t.attr('title'); // get it once since it won't change
		    // only apply logic if the element has the attribute
		    if (title) {
				// on blur, set value to title attr if text is blank
				t.blur(function () {
					if (t.val() == '') {
						t.val(title);
						t.addClass('blur');
					}
				});
				// on focus, set value to blank if current value
				// matches title attr
				t.focus(function () {
					if (t.val() == title) {
						t.val('');
						t.removeClass('blur');
					}
				});
				// clear the pre-defined text when form is submitted
				t.parents('form:first').submit(function() {
					if (t.val() == title) {
						t.val('');
						t.removeClass('blur');
					}
				});
				t.blur(); // now change all inputs to title
			}
		});
	}

    // ------------------------------------------- //

    /* 
    --- css properties selectors --- 
    */ 
	jQuery.extend(jQuery.expr[':'],{
		float: function(a) {
		  return (jQuery(a).css("float") === "left" || jQuery(a).css("float") === "right");
		},
		inline: function(a) {
		  return jQuery(a).css("display") === "inline";
		},
		marginx: function(a) {
		  return ((parseInt(jQuery(a).css("margin-left")) > 0) || (parseInt(jQuery(a).css("margin-right")) > 0));
		},
		marginy: function(a) {
		  return ((parseInt(jQuery(a).css("margin-bottom")) > 0) || (parseInt(jQuery(a).css("margin-top")) > 0));
		},
		margin: function(a) {
		  return ((parseInt(jQuery(a).css("margin-left")) > 0) || (parseInt(jQuery(a).css("margin-right")) > 0) || (parseInt(jQuery(a).css("margin-bottom")) > 0) || (parseInt(jQuery(a).css("margin-top")) > 0));
		}
	});

    // --------------------------- //
    
    /* 
    calculates offset right of an element 
    because only offset left is provided
    out of the box
    */

    $.fn.offsetRight = function (){
         var $this = $(this);
        if($this.length != 0) {
            var $thisOffsetLeft = $this.offset().left;
            var $windowWidth = $(window).innerWidth();
            //bacause there is only offset left -> offsetRigth = winndow.width -offset.left
           return $windowWidth-$thisOffsetLeft; 
        }
    }
}
	


	// ------------------------------- //
	
	module.exports = extend;
window.extend = extend;
}.call(window, window);