(function(){
	const alignClasses = ['v-top', 'v-middle', 'v-bottom', 'h-left', 'h-center', 'h-right'];
	$(".arctur-vertical-align").each(function(index, item) {
		const $item = $(item);
		// get h / v position
		const classList = $item.prop("classList");
		let usedClasses = '';
		for(const className of classList) {
			if(alignClasses.indexOf(className) !== -1) {
				if(usedClasses) {
					usedClasses += ' ';
				}
				usedClasses += className;
			}
		}
		$item.removeClass(usedClasses);
		
		const container = `<div class="cell ${usedClasses}"></div>`;
		const outerContainer = `<div class="arctur-vertical-align-container">${container}</div>`;
		$item.wrap(outerContainer);
		
	});
})()